import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title + " | Krogklubben.se"}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Krogklubben.se | För krogpersonal",
  keyword:
    "krogkortet, krogklubben, krogpersonal, krog erbjudanden",
  desc: "Krogklubben.se är för alla krogpersonal - Gratis att gå med, utnyttja erbjudanden.",
};

export default Meta;
