const footerMenuList = [
  {
    id: 1,
    title: "Krogklubben",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/om",
        text: "Om Krogklubben",
      },
      {
        id: 4,
        href: "/how-to",
        text: "Hur du går med",
      },
      {
        id: 5,
        href: "/faq",
        text: "Vanliga frågor",
      },
      {
        id: 2,
        href: "/personal",
        text: "För krogpersonal",
      },
      {
        id: 3,
        href: "/arbetsgivare",
        text: "För arbetsgivare",
      },
      {
        id: 3,
        href: "/support",
        text: "Kundtjänst",
      },
    ],
  },
  {
    id: 2,
    title: "Konto",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "https://app.krogklubben.se/register",
        text: "Gå med",
      },
      {
        id: 2,
        href: "https://app.krogklubben.se/login",
        text: "Logga in",
      },
      {
        id: 3,
        href: "https://app.krogklubben.se/forgot",
        text: "Glömt lösenord",
      },
    ],
  },
  {
    id: 3,
    title: "APP",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/download",
        text: "iPhone",
      },
      {
        id: 2,
        href: "/download",
        text: "Android",
      },
      {
        id: 3,
        href: "https://app.krogklubben.se",
        text: "Webb",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
